// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-destinations-js": () => import("./../../../src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-acquisition-js": () => import("./../../../src/pages/practice_acquisition.js" /* webpackChunkName: "component---src-pages-practice-acquisition-js" */),
  "component---src-pages-sunset-opportunities-js": () => import("./../../../src/pages/sunset_opportunities.js" /* webpackChunkName: "component---src-pages-sunset-opportunities-js" */)
}

